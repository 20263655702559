import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/data/devops_data/jenkins_home/workspace/BmmsFrontend/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.number.constructor";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
import { login as _login, logout as _logout, getInfo as _getInfo, refreshToken as _refreshToken } from '@/api/user';
import { getToken, setToken, removeToken, setExpire } from '@/utils/auth';
import { fetchPaymentChannel } from '@/api/corporation';
import { resetRouter } from '@/router';
import { getExpire } from '@/utils/auth';
import store from '@/store';
var state = {
  token: getToken(),
  name: '',
  roles: [],
  corporationID: 0,
  corporationName: '',
  balance: 0,
  expire: Number(getExpire() || 0),
  user: {}
};
var mutations = {
  SET_TOKEN: function SET_TOKEN(state, _ref) {
    var token = _ref.token,
      expire = _ref.expire;
    state.token = token;
    setToken(token, expire);
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLE: function SET_ROLE(state, role) {
    state.roles = role;
  },
  SET_CORPORATION_ID: function SET_CORPORATION_ID(state, id) {
    state.corporationID = id;
  },
  SET_CORPORATION_NAME: function SET_CORPORATION_NAME(state, cpname) {
    state.corporationName = cpname;
  },
  SET_EXPIRE: function SET_EXPIRE(state, data) {
    state.expire = data;
    setExpire(data, new Date(setExpire));
  },
  SET_USER: function SET_USER(state, data) {
    state.user = data;
  }
};
var actions = {
  // user login
  login: function login(_ref2, data) {
    var commit = _ref2.commit;
    var _data$userInfo = data.userInfo,
      email = _data$userInfo.email,
      password = _data$userInfo.password;
    return new Promise(function (resolve, reject) {
      _login({
        captcha: data.query,
        email: email.trim(),
        password: password
      }).then(function (response) {
        var data = response;
        commit('SET_TOKEN', {
          token: data.token,
          expire: data.expire
        });
        commit('SET_EXPIRE', new Date(data.expire).getTime());
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // get user info
  getInfo: function getInfo(_ref3, data) {
    var commit = _ref3.commit,
      state = _ref3.state;
    return new Promise(function (resolve, reject) {
      _getInfo(data || state.token).then(function (response) {
        var data = response.data;
        if (!data) {
          reject('Verification failed, please Login again.');
        }
        var email = data.email,
          role = data.role,
          corporation_id = data.corporation_id,
          corporation = data.corporation,
          balance = data.balance;
        data['roles'] = role;
        commit('SET_USER', data);
        commit('SET_NAME', email);
        commit('SET_CORPORATION_NAME', corporation.name);
        commit('SET_CORPORATION_ID', corporation_id);
        commit('SET_ROLE', role);
        fetchPaymentChannel(corporation_id).then(function (res) {
          store.dispatch('corporation/change', {
            key: 'PaymentChannel',
            value: res.items
          });
        });
        state.balance = balance;
        resolve(data);
      }).catch(function (error) {
        // reject(error)
        // store.dispatch('user/refreshToken').then(() => {
        //   store.dispatch('user/getInfo').then(data => {
        //     resolve(data)
        //   }).catch(() => {
        //     reject(error)
        //   })
        // }).catch(() => {
        //   reject(error)
        // })
        // if (navigator.userAgent.indexOf('Windows') > -1) {
        commit('SET_TOKEN', '');
        commit('SET_EXPIRE', 0);
        state.roles = [];
        removeToken();
        // }
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
      state = _ref4.state;
    return new Promise(function (resolve, reject) {
      _logout(state.token).then(function () {
        commit('SET_TOKEN', '');
        commit('SET_EXPIRE', 0);
        state.roles = [];
        removeToken();
        resetRouter();
        resolve();
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // remove token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    return new Promise(function (resolve) {
      commit('SET_TOKEN', '');
      commit('SET_EXPIRE', 0);
      state.roles = [];
      removeToken();
      resolve();
    });
  },
  refreshToken: function refreshToken(_ref6) {
    var commit = _ref6.commit;
    return new Promise(function (resolve, reject) {
      _refreshToken().then(function (response) {
        var _response$data, _response$data2, _response$data3;
        commit('SET_TOKEN', {
          token: response === null || response === void 0 ? void 0 : (_response$data = response.data) === null || _response$data === void 0 ? void 0 : _response$data.token,
          expire: response === null || response === void 0 ? void 0 : (_response$data2 = response.data) === null || _response$data2 === void 0 ? void 0 : _response$data2.expire_at
        });
        commit('SET_EXPIRE', new Date(response === null || response === void 0 ? void 0 : (_response$data3 = response.data) === null || _response$data3 === void 0 ? void 0 : _response$data3.expire_at).getTime());
        resolve(response);
      }).catch(function (data) {
        commit('SET_TOKEN', '');
        commit('SET_EXPIRE', 0);
        removeToken();
        reject(_objectSpread({}, data));
      });
    });
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};