import _readOnlyError from "/data/devops_data/jenkins_home/workspace/BmmsFrontend/node_modules/@babel/runtime-corejs2/helpers/esm/readOnlyError.js";
var state = {};
try {
  // eslint-disable-next-line no-const-assign
  ({
    log: JSON.parse(window.localStorage['log'] || '[]'),
    transactionIndex: JSON.parse(window.localStorage['transactionIndex'] || '[]'),
    payment_order: JSON.parse(window.localStorage['payment_order'] || '[]'),
    statistic: JSON.parse(window.localStorage['statistic'] || '[]'),
    summary: JSON.parse(window.localStorage['summary'] || '[]'),
    userManagerIndex: JSON.parse(window.localStorage['userManagerIndex'] || '[]')
  }), _readOnlyError("state");
  // eslint-disable-next-line no-empty
} catch (e) {}
var mutations = {
  CHANGE: function CHANGE(state, _ref) {
    var key = _ref.key,
      value = _ref.value;
    if (state.hasOwnProperty(key)) {
      state[key] = value;
      window.localStorage[key] = JSON.stringify(value);
    }
  }
};
var actions = {
  change: function change(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE', data);
  }
};
export default {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};