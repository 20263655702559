import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _defineProperty from "/data/devops_data/jenkins_home/workspace/BmmsFrontend/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
//
//
//
//
//
//
//
//
//
//
//
//

import { isExternal } from '@/utils/validate';
import { mapState } from 'vuex';
export default {
  props: {
    to: {
      type: String,
      required: true
    },
    url: {
      type: String,
      default: ''
    },
    item: {
      type: Object,
      default: function _default() {}
    },
    parent: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: _objectSpread({}, mapState({
    sidebar: function sidebar(state) {
      return state.app.sidebar;
    }
  })),
  methods: {
    isExternal: isExternal,
    clickRouter: function clickRouter() {
      var url = process.env.VUE_APP_PDS_URL + this.url;
      this.$router.push({
        path: "/iframe/go",
        query: {
          route: "".concat(JSON.stringify(this.item)),
          parent: JSON.stringify(this.parent),
          url: url
        }
      });
    },
    linkProps: function linkProps(url) {
      if (isExternal(url)) {
        return {
          is: 'a',
          href: url,
          target: '_blank',
          rel: 'noopener'
        };
      }
      return {
        is: 'router-link',
        to: url
      };
    }
  }
};